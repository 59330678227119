import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about-defense-in-depth"
    }}>{`About Defense in Depth`}</h1>
    <p>{`Ocelot's core philosophy revolves around the concept of defense in depth.
Instead of relying on a single layer of security, our solution provides multiple layers of protection at every stage of
your microservices communication, from the application layer down to the network layer. This multi-layered approach
ensures that even if one layer is compromised, your entire system remains resilient.`}</p>
    <p><em parentName="p">{`Defense in Depth`}</em>{` is a comprehensive approach to information security conceived by the National
Security Agency (NSA). The main idea behind this approach is to defend a system through a set of independent security mechanisms,
each organized and protecting a specific layer of the IT Landscape. Quoting directly the SANS Institute on a security
assessment of the Department of Defense (DoD):`}</p>
    <blockquote>
      <p parentName="blockquote">{`Defense in depth is an age-old military strategy. The most thought visualization is a castle during the Middle Ages.
The castle did not necessarily depend on its walls to protect itself. It was surrounded by a moat, guard towers, and
a bridge with controlled access to the castle amongst other things. If an enemy would want to defeat this, it would
have to take into account all these defensive measures put into place. Thus, DoD uses this strategy to defend its
information networks.`}</p>
    </blockquote>
    <p>{`Three main areas need to be covered to adopt effectively the Defense in Depth approach, namely:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Physical controls`}</strong>{`: not in the scope of this document, but you can find at this layer all security controls in place to physically defend a data center location from unauthorized access. The Cloud Service Provider (CSP) implements these kinds of controls and is responsible for this layer of protection;`}</li>
      <li parentName="ul"><strong parentName="li">{`Technical controls`}</strong>{`: These controls focus on protecting resources and systems through the specific combination of hardware and software;`}</li>
      <li parentName="ul"><strong parentName="li">{`Administrative controls`}</strong>{`: A set of policies and processes within an Enterprise enforce these controls. These can also be executed and automated through specific technical controls.`}</li>
    </ul>
    <p>{`For obvious reasons, our focus is on technical controls and, in this document, we will not cover the other two areas.`}</p>
    <h2 {...{
      "id": "technical-controls"
    }}>{`Technical Controls`}</h2>
    <p>{`Within the technical conrtols area, we can find the following layers:`}</p>
    <p><img alt="Technical Controls" src={require("../drawings/technical-controls.svg")} /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Process and Policy`}</strong>{`: The first layer of defense is having a well-defined and comprehensive set of security processes and policies to ensure the security of our customers’ data and users.`}</li>
      <li parentName="ul"><strong parentName="li">{`Infrastructure`}</strong>{`: Secure east-west traffic and extend and fortify infrastructure security components such as Web Application Firewall (WAF) and API Gateways, permeating authorization checks beyond the edge.`}</li>
      <li parentName="ul"><strong parentName="li">{`Application`}</strong>{`: Allow teams to easily define in detail which service is allowed to communicate to each other, the security requirements for each operation and API call, and secure the whole application layer by rejecting all not authorized traffic.`}</li>
      <li parentName="ul"><strong parentName="li">{`User`}</strong>{`: Enforce and ease RBAC controls on users and current sessions, enabling the team to efficiently extend and granularly control the security level required for each specific operation. Ocelot can dynamically require extra factors, ensuring the fulfilment of specific business requirements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Data`}</strong>{`: The teams need to be able to assign specific scopes to the different types of data owned by their applications, to ensure data access to sensitive data exclusively to specific workloads and under specific conditions.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      